<template>
  <div class="dropdown_form col-12">
    <template v-if="type !== 'file'">
      <label :for="id">{{ label }}</label>
      <the-mask
        v-if="mask.length"
        class="input_text"
        :class="inputClass"
        :type="type"
        :value="value"
        :id="id"
        :mask="mask"
        :masked="masked"
        @input="$event => $emit('input', $event)"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
      />
      <money
        class="input_text"
        :class="inputClass"
        v-else-if="type === 'money'"
        :value="value"
        v-bind="money"
        @input="$event => $emit('input', $event)"
        :autocomplete="autocomplete"
      />
      <input
        v-else
        class="input_text"
        :class="inputClass"
        :type="type"
        :value="value"
        :id="id"
        @input="$event => $emit('input', $event.target.value)"
        :readonly="readonly"
        :style="{backgroundColor: readonly ? '#f5f3f3' : ''}"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
      />
    </template>
    <template v-else>
      <label :for="id">{{ label }}</label>
      <input :style="{backgroundColor: readonly ? '#f5f3f3' : ''}" :id="id" hidden type="file" @change.prevent="inputFile" />
      <label class="input_text type_file" :id="'file-label-'+id" :for="id">{{ fileName }}</label>
    </template>
    <small class="input-error">{{ error }}</small>
    <small class="input-info">{{ info }}</small>
  </div>
</template>

<script>
import { v4 } from 'uuid'
import { TheMask } from 'vue-the-mask'
import { Money } from 'v-money'

export default {
  components: { TheMask, Money },

  props: {
    label: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    value: {
      required: true,
      default: ''
    },
    mask: {
      required: false,
      default: ''
    },
    masked: {
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    inputClass: String
  },

  methods: {
    inputFile (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        this.$emit('input', e.target.result)
      }
      reader.readAsDataURL(file)
    }
  },

  data: () => ({
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false
    }
  }),

  computed: {
    id () {
      return `form-field-${v4()}`
    },
    fileName () {
      if (this.type !== 'file') return
      if (this.value && typeof this.value === 'object' && this.value.name) {
        return this.value.name
      }
      return 'Selecionar...'
    },
    error () {
      if (!this.rules) return ''
      if (!this.$formSended && !this.value) return ''

      for (const rule of this.rules) {
        const result = rule(this.value)
        if (result !== true) return result
      }
      return ''
    }
  }
}
</script>

<style scoped>
.dropdown_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.input_text {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  border-radius: 0.5rem;
}
.input-error {
  color: #ff6e91;
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.input-info {
  color: #ababab;
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.input_text.type_file {
  cursor: pointer;
}
input[type=date]:before {
  left: calc(100% - 30px);
}
</style>
